import React from "react";
import { Card, Typography } from "@material-tailwind/react";

const faqs = [
  {
    title: "What Software means?",
    desc: "Software refers to the non-tangible component of computers, which is opposite to the hardware (processor, memory devices, and motherboard), including application software (Microsoft Office, Adobe, etc) and system software (drivers, utility software).",
  },
  {
    title: "What SaaS means?",
    desc: "SaaS means Software as a Service and refers to accessing the software via the internet instead of installing and maintaining the software yourself.",
  },
  {
    title: "How to find the best software company for your business?",
    desc: "Finding the best software for your business involves a thorough process that ensures the tool you select aligns with your business needs, budget, and future goals. Using our curated list that synthesizes software pricing, capabilities, alternatives, and reviews can significantly streamline this process.",
  }
];

export default function Faq() {
  return (
    <section className="px-8 py-20">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-5">
        <div className="col-span-2 mb-20 text-center">
          <Typography
            variant="h2"
            color="blue-gray"
            className="mb-4 text-left text-xl !leading-snug lg:text-3xl"
          >
            Frequently Asked Question (FAQ)
          </Typography>
        </div>

        <div className="col-span-3 grid grid-cols-1 gap-10 lg:ml-44 lg:mr-24">
          {faqs.map(({ title, desc }) => (
            <Card key={title} shadow={false} color="transparent">
              <Typography color="blue-gray" className="pb-4" variant="h5">
                {title}
              </Typography>
              <Typography className="font-normal !text-gray-500">
                {desc}
              </Typography>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}