import React from "react";
import {
  Input,
  Typography,
} from "@material-tailwind/react";

export default function Header() {
  return (
    <div className="m-8">
      <div className="relative w-full bg-black rounded-2xl overflow-hidden">
        <div className="grid px-8 py-40">
          <div className="container relative z-10 m-auto">
            <div className="-mt-12 mb-12">
              <img className="w-1/5" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/software/ct-tekpon.png" alt="cubes" />
            </div>
            <Typography
              variant="h3"
              color="white"
              className="lg:text-3xl font-normal text-xl mb-4"
            >
              Find the best
            </Typography>
            <Typography
              variant="h1"
              color="white"
              className="lg:text-5xl text-3xl"
            >
              Software Companies
            </Typography>
            <Typography
              color="white"
              variant="lead"
              className="mt-2 w-full lg:w-5/12 opacity-80"
            >
              Explore top software products, analyzing key insights on pricing, detailed reviews, costs, and alternatives to find the best fit for your needs.
            </Typography>
          </div>
        </div>
        <img className="absolute right-0 top-0" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/software/header.jpg" alt="cubes" />
      </div>
     
    </div>
  );
}