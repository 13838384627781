import React, { useState, useEffect } from "react";
import { Card, CardBody, Chip, Button } from "@material-tailwind/react";
import { CompanyType } from "types/types.js";

interface CompaniesSectionProps {
  currentCompanies: CompanyType[];
}

export default function CompaniesSection({ currentCompanies }: CompaniesSectionProps){

  return (
    <div className="m-8">
      <div className="container relative w-full mx-auto overflow-hidden">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {currentCompanies && currentCompanies.map(company => (
            <Card key={company.id} className="border">
              <CardBody>
                <div className="flex justify-between">
                  <img className="w-20 border rounded-xl" src={company.logo} alt="Company Logo" />
                  <div>
                    <div className="flex items-center mb-1">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.2671 3.45486C6.91036 3.4035 7.52102 3.15049 8.0121 2.73186C8.56677 2.25945 9.27153 2 10.0001 2C10.7287 2 11.4334 2.25945 11.9881 2.73186C12.4792 3.15049 13.0898 3.4035 13.7331 3.45486C14.4595 3.51292 15.1415 3.82782 15.6568 4.34313C16.1721 4.85843 16.487 5.54043 16.5451 6.26686C16.5961 6.90986 16.8491 7.52086 17.2681 8.01186C17.7405 8.56652 18 9.27129 18 9.99986C18 10.7284 17.7405 11.4332 17.2681 11.9879C16.8495 12.4789 16.5965 13.0896 16.5451 13.7329C16.487 14.4593 16.1721 15.1413 15.6568 15.6566C15.1415 16.1719 14.4595 16.4868 13.7331 16.5449C13.0898 16.5962 12.4792 16.8492 11.9881 17.2679C11.4334 17.7403 10.7287 17.9997 10.0001 17.9997C9.27153 17.9997 8.56677 17.7403 8.0121 17.2679C7.52102 16.8492 6.91036 16.5962 6.2671 16.5449C5.54067 16.4868 4.85867 16.1719 4.34337 15.6566C3.82807 15.1413 3.51316 14.4593 3.4551 13.7329C3.40375 13.0896 3.15074 12.4789 2.7321 11.9879C2.2597 11.4332 2.00024 10.7284 2.00024 9.99986C2.00024 9.27129 2.2597 8.56652 2.7321 8.01186C3.15074 7.52077 3.40375 6.91012 3.4551 6.26686C3.51316 5.54043 3.82807 4.85843 4.34337 4.34313C4.85867 3.82782 5.54067 3.51292 6.2671 3.45486ZM13.7071 8.70686C13.8893 8.51826 13.9901 8.26566 13.9878 8.00346C13.9855 7.74126 13.8803 7.49045 13.6949 7.30504C13.5095 7.11963 13.2587 7.01446 12.9965 7.01219C12.7343 7.00991 12.4817 7.1107 12.2931 7.29286L9.0001 10.5859L7.7071 9.29286C7.5185 9.1107 7.2659 9.00991 7.0037 9.01219C6.74151 9.01447 6.49069 9.11963 6.30529 9.30504C6.11988 9.49045 6.01471 9.74126 6.01243 10.0035C6.01015 10.2657 6.11095 10.5183 6.2931 10.7069L8.29311 12.7069C8.48063 12.8943 8.73494 12.9996 9.0001 12.9996C9.26527 12.9996 9.51958 12.8943 9.7071 12.7069L13.7071 8.70686Z" fill="#4CAF50"/>
                      </svg>
                      <p className="ml-1">Verified by</p>
                      <a href={company.tekpon_slug} target="_blank">
                        <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/software/logo-tekpon.png" className="h-6" alt="Verification Logo" />
                      </a>
                    </div>
                    <div className="flex">
                      <p className="mr-2">Score: {company.score} </p>
                      <Chip
                        variant="ghost"
                        color={company.chipColor}
                        size="sm"
                        value={company.chipValue}
                        className="capitalize rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h5 className="mt-4 mb-2 text-lg font-semibold text-primary">{company.slug.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</h5>
                  <p className="mb-4 text-sm opacity-80">{company.description}</p>
                  {company.tags && company.tags.map(tag => (
                    <a key={tag} className="mr-2 font-bold" href={`#${tag}`}>#{tag}</a>
                  ))}
                </div>
                <a href={`/software/${company.slug}`}>
                  <Button color="gray" variant="outlined">View Profile</Button>
                </a>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}
