import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

function NewsCard({ img, desc, href }) {
  return (
    <Card
      color="transparent"
      shadow={false}
    >
      <a href={href} target="_blank">
        {img && (
          <CardHeader floated={false} className="mx-0 mb-4 shadow-none border">
            <img
              src={img}
              className="h-full w-full object-cover"
            />
          </CardHeader>
        )}
        <CardBody className="p-0">
          <Typography className="mb-6 ml-2 font-bold">
            {desc}
          </Typography>
        </CardBody>
      </a>
    </Card>
  );
}

const members = [
  {
    img: `https://i.imgur.com/19TibIa.jpeg`,
    desc: "8 Best SaaS Tools for your Startup in 2024",
    href: "https://www.creative-tim.com/software/news/8-best-saas-tools-for-your-startup-in-2024"
  },
  
  {
    img: "",
    desc: "See more",
    href: "https://www.creative-tim.com/software/news"
  }
];

export default function LatestNews() {
  return (
    <section className="py-10 px-8">
      <div className="container mx-auto">
        <div className="mb-14">
          <Typography
            variant="h2"
            color="blue-gray"
            className="mt-6 mb-3 lg:text-2xl"
          >
            Latest News
          </Typography>
          <Typography variant="lead" className="!text-gray-600 max-w-xl">
            Find the latest Software news to stay ahead of the curve in technology trends, innovations, and updates.
          </Typography>
        </div>
        <div className="grid lg:gap-y-28 gap-y-16 gap-x-10 md:grid-cols-2 lg:grid-cols-4 items-center">
          {members.map((props, key) => (
            <NewsCard key={key} {...props} />
          ))}
        </div>
      </div>
    </section>
  );
}