import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Header from "../../components/tekpon/header";
import Categories from "../../components/tekpon/categories";
import CompaniesSection from "../../components/tekpon/companies";
import LatestNews from "../../components/tekpon/latest-news";
import Faq from "../../components/tekpon/faq";
import Subscribe from "../../components/tekpon/subscribe";
import Footer from "../../components/footer";
import { SoftwareNavbar } from "../../components/navbar";
import PropTypes from "prop-types";
import { fetchCompanies } from "../../utils/companies";
import { Companies } from "../../db/companies.js";
import { CompanyType } from "types/types.js";

const Pagination = ({ currentPage, totalPages, paginate }) => (
  <div className="flex items-center justify-center gap-4 mt-6">
    <button
      disabled={currentPage === 1}
      onClick={() => paginate(currentPage - 1)}
      className="flex items-center gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
    >
      Previous
    </button>
    <div className="flex items-center gap-2">
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i + 1}
          className={`relative h-10 w-10 select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase ${currentPage === i + 1 ? 'border border-gray-300' : 'text-gray-900'} transition-all hover:bg-gray-900/10 active:bg-gray-900/20`}
          onClick={() => paginate(i + 1)}
        >
          <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {i + 1}
          </span>
        </button>
      ))}
    </div>
    <button
      disabled={currentPage === totalPages}
      onClick={() => paginate(currentPage + 1)}
      className="flex items-center gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-lg select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
    >
      Next
    </button>
  </div>
);

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
};

const SoftwareIndex = ({ slug }) => {
  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const companiesRef = useRef(null);

  useEffect(() => {
    try {
      setCompanies(Companies);
      setLoading(false);
    } catch (error) {
      console.error('Error loading the data:', error);
      setError(error.toString());
      setLoading(false);
    }
  }, []);

  const { asPath } = useRouter();
  const [mobileNav, setMobileNav] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filteredCompanies = companies.filter(company => 
    selectedCategory === 'all' || company.tags.includes(selectedCategory)
  );
  
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage] = useState(9);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentCompanies = filteredCompanies.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredCompanies.length / articlesPerPage);

  return (
    <>
      <Head>
        <title>Find the Best Software Products for Your Business</title>
        <meta 
          name="title" 
          content="Find the Best Software Products for Your Business" 
        />
        <meta 
          name="description" 
          content="Explore top software products, analyzing key insights on pricing, detailed reviews, costs, and alternatives to find the best fit for your needs."   
        />
        <link
          rel="canonical"
          href="https://www.creative-tim.com/software"
        />
        <link rel="icon" href="/ct-favicon.ico" />
        <meta property="og:title" content="Find the Best Software Products for Your Business" />
        <meta property="og:url" content="https://www.creative-tim.com/software" />
        <meta property="og:description" content="Explore top software products, analyzing key insights on pricing, detailed reviews, costs, and alternatives to find the best fit for your needs." />
      </Head>
      <SoftwareNavbar />
      <div className="relative w-full h-full bg-white">
        <Header />
        <Categories setSelectedCategory={setSelectedCategory} />
        <div ref={companiesRef}>
          <CompaniesSection currentCompanies={currentCompanies}/>
        </div>
        <Pagination 
          currentPage={currentPage} 
          totalPages={totalPages} 
          paginate={paginate} 
        />
        <LatestNews />
        <Faq />
        <Footer />
      </div>
    </>
  );
};

SoftwareIndex.propTypes = {
  slug: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SoftwareIndex;
